export const kpi = [
	{
		name: "leadSource",
		value: "Lead Source",
		checked: false,
		type: "checkbox",
		id: "k1",
	},
	{
		name: "endStatus",
		value: "End Status",
		checked: false,
		type: "checkbox",
		id: "k2",
	},
	{
		name: "costPerBooking",
		value: "Cost per booking",
		checked: false,
		type: "checkbox",
		id: "k3",
	},
];

export const formatKpiArr = (arr) => {

	const formattedArr = [];
	arr.forEach((data) => formattedArr.push(
		{
			name: data.name, 
			selected: data.checked
		}
	))

	return formattedArr;
};
