import React, { useReducer, useState } from 'react'
import { ACTIONS } from '../actions/Jobber'
import JobberImg from '../assets/images/white_jobber.png'
import { JOBBER_INITIAL_STATE, reducer } from '../reducers/Jobber'
import { formatMobileNumber } from '../utils/formatters'
import { registerJobberUser } from '../services/Jobber'
import Spinner from '../components/UI/Spinner'
import { jobberAuthorizeUrl } from '../services/Jobber'
import { toast } from 'react-toastify'

const Input = ({ label, type, name, value, onChange, placeholder }) => {

  return (
    <div className='mb-5'>
      <label className='text-base font-600 mb-2 block'>{label}</label>
      <input
				type={type}
				className={'w-full bg-transparent border border-[#cccccc] rounded h-10 px-4 text-base font-400 text-black focus:outline-none focus:border focus:border-blue-500 placeholder-[#cccccc]'}
				value={value}
				name={name}
				onChange={onChange}
				placeholder={placeholder}
			/>
    </div>
  )
}

const RegisterJobber = () => {

  const [state, dispatch] = useReducer(reducer, JOBBER_INITIAL_STATE)
  const [loading, setLoading] = useState(false)

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    businessHome,
    website 
  } = state

  const inputHanlder = (e) => {
    let { value, name } = e.target;
    if (name === 'phoneNumber' || name === 'businessHome') {
      value = formatMobileNumber(e.target.value)
    }
    dispatch({ type: ACTIONS.INPUT_HANDLER, payload: { key: name, value: value }})
  }

  const submitForm = async (e) => {
    e.preventDefault();
    
    try {
      setLoading(true)
      const res = await registerJobberUser(state)
      if (res.status) {
        window.open(jobberAuthorizeUrl(res.id) ,'_blank')
        dispatch({ type: ACTIONS.CLEAR_FIELDS })
      } else {
        toast.error(res.message)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <section className="min-h-screen relative">
      <div className='bg-[#002436] h-20 w-full'>
        <img src={JobberImg} width={80} height={80} className='z-20' alt='Jobber logo' />
      </div>

      <div className='sm:w-443 mx-auto px-8 sm:px-0 py-20'>
        <div className='border-b border-[#cccccc] w-full pb-3'>
          <h1 className='font-600 text-xl md:text-3xl'>
            Registration Form For Jobber
          </h1>
        </div>

        <form onSubmit={submitForm} className='mt-10'>
          <Input
            type='text'
            label='First Name'
            name='firstName'
            placeholder='John'
            value={firstName}
            onChange={inputHanlder}
          />

          <Input
            type='text'
            label='Last Name'
            name='lastName'
            placeholder='Doe'
            value={lastName}
            onChange={inputHanlder}
          />

          <Input
            type='email'
            label={'Email'}
            name={'email'}
            placeholder={'johndoe@gmail.com'}
            value={email}
            onChange={inputHanlder}
          />

          <Input
            type='phone'
            label={'Mobile Phone'}
            name={'phoneNumber'}
            placeholder={'1234567890'}
            value={phoneNumber}
            onChange={inputHanlder}
          />

          <Input
            type='phone'
            label={'Business Phone'}
            name={'businessHome'}
            placeholder={'1234567890'}
            value={businessHome}
            onChange={inputHanlder}
          />

          <Input
            type='text'
            label={'Website'}
            name={'website'}
            placeholder={'www.google.com'}
            value={website}
            onChange={inputHanlder}
          />

          <button type='submit' className='bg-[#002436] w-48 h-12 rounded text-white text-base font-500 mt-10 mx-auto block'>
            {loading ? <Spinner width={'w-6'} height={'h-6'} borderColor={'white'} borderWidth={2} /> : 'Connect Jobber'}
          </button>
        </form>
      </div>
    </section>
  )
}

export default RegisterJobber
