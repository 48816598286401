import React from 'react'
import { CircularProgress } from '@mui/material'

const IntegrationCard = ({ icon, title, description, onClick, isActive, loading, disabled = false }) => {
  return (
    <div className='shadow-[0px_0px_10px_1px_rgba(0,0,0,0.1)] rounded-md border p-6 w-full h-64'>
      <div className='flex flex-col h-full'>
        <div className='w-14 h-14 rounded'>
          {icon}
        </div>
        <div className='flex flex-col justify-between flex-grow mt-3'>
          <div>
            <h2 className='font-600 text-base tracking-tight'>{title}</h2>
            <p className='font-400 text-sm text-gray-400 mt-1.5 tracking-tight'>
              {description}
            </p>
          </div>
          {<button
            className={'text-black rounded text-center font-400 w-full h-8 bg-[#dbdbdf77] mt-4 flex items-center justify-center '+ (disabled && 'disabled:bg-slate-100 disabled:text-gray-300 cursor-not-allowed')}
            onClick={onClick}
            disabled={disabled}
          >
            {
              loading ? <CircularProgress size={22} sx={{ color: 'black' }} /> : 
              (isActive ? 'Disconnect' : 'Connect')
            }
          </button>}
        </div>
      </div>
    </div>
  )
}

export default IntegrationCard
