import React, { useCallback, useEffect, useState } from 'react'
import { MediumSpinner } from '../UI/Spinner'
import ImageCard from '../Card/ImageCard'
import SectionHeading from '../UI/SectionHeading'
import { getImagesByNumber } from '../../api'

const ImageSection = ({ clientId, number }) => {

	const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const fetchData = useCallback(async() => {
    try {
      setLoading(true)
      const res = await getImagesByNumber(clientId, number)
      setData(res.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }

	}, [number])

	useEffect(() => {
		fetchData()

	}, [fetchData])

  return (
		<>
			{data.length > 0 && (
				<section className="bg-white py-6">
					<SectionHeading heading="Images" />
					<div className="mt-4">
						{loading ? (
							<MediumSpinner />
						) : (
							<div className="flex flex-wrap gap-4">
								{data.map((item) =>
									item.images.map((image, index) => (
										<ImageCard key={index} href={image} src={image} />
									))
								)}
							</div>
						)}
					</div>
				</section>
			)}
		</>
	);
}

export default ImageSection
