import React, { useCallback, useEffect, useState } from "react";
import WidgetCard from "../Card/WidgetCard";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import TextsmsIcon from "@mui/icons-material/Textsms";
import QuickreplyIcon from "@mui/icons-material/Quickreply";
import { useAuth } from "../../context/AuthContext";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GoogleChatIcon from "../../assets/svgs/google-chat-icon.svg";
import { Box, Modal, Typography } from "@mui/material";
import QrCode from "../../assets/images/qr_code.png";
import { getAnalytics } from "../../api";

const DashboardCard = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getAnalytics(currentUser?.displayName);
      setData(res.data.data);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8">
      {data.calls < 5 && currentUser.status === "Expired" ? (
        <Modal
          open={true}
          sx={{
            "&.MuiModal-root": {
              zIndex: "39",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "100%", md: "60%", lg: "50%" },
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "12px",
            }}
          >
            <Typography
              component="h3"
              sx={{ fontWeight: "600", marginBottom: "20px" }}
            >
              Your account is complete. Scan this QR code to begin forwarding
              calls to your new SnapPicFix account:
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <img src={QrCode} alt="Qr code" width={300} />
            </Box>
            <Typography component="p" sx={{ marginTop: "20px" }}>
              Your Twilio Number is: {currentUser.number}
            </Typography>
          </Box>
        </Modal>
      ) : (
        <>
          <WidgetCard
            loading={loading}
            title={`Total Calls`}
            value={data.calls || 0}
            icon={<PhoneCallbackIcon sx={{ fontSize: 30, color: "green" }} />}
          />

          {data.digitalLeads > 0 && (
            <WidgetCard
              loading={loading}
              title={"Total Digital Leads"}
              value={data.digitalLeads || 0}
              icon={<CheckCircleIcon sx={{ fontSize: 30, color: "green" }} />}
            />
          )}

          <WidgetCard
            loading={loading}
            title={`Total Texts Sent`}
            value={data.smsSent || 0}
            icon={<TextsmsIcon sx={{ fontSize: 40, color: "black" }} />}
          />

          {data.inboundSms > 0 && (
            <WidgetCard
              loading={loading}
              title="Customers replied to Text"
              value={data.inboundSms}
              icon={<QuickreplyIcon sx={{ fontSize: 40, color: "green" }} />}
            />
          )}

          {data.hangupTextSent > 0 && (
            <WidgetCard
              loading={loading}
              title="Texts sent to hangups"
              value={data.hangupTextSent}
              icon={<TextsmsIcon sx={{ fontSize: 40, color: "black" }} />}
            />
          )}

          {data.hangupTextreceived > 0 && (
            <WidgetCard
              loading={loading}
              title="Texts received from hangups"
              value={data.hangupTextreceived}
              icon={<QuickreplyIcon sx={{ fontSize: 40, color: "black" }} />}
            />
          )}

          <WidgetCard
            loading={loading}
            title={`Voicemails Recorded & Transcribed`}
            value={data.voicemailCount || 0}
            icon={<VoicemailIcon sx={{ fontSize: 40, color: "green" }} />}
          />

          {data.forms > 0 && (
            <WidgetCard
              loading={loading}
              title="Service Requests Created (Forms submitted)"
              value={data.forms}
              icon={<WysiwygIcon sx={{ fontSize: 40, color: "green" }} />}
            />
          )}

          {data.crmCount > 0 && (
            <WidgetCard
              loading={loading}
              title="New customers added to the CRM"
              value={data.crmCount}
              icon={<CheckCircleIcon sx={{ fontSize: 40, color: "green" }} />}
            />
          )}

          {data.googleChats > 0 && (
            <WidgetCard
              loading={loading}
              title="Google chat submission"
              value={data.googleChats}
              icon={
                <img
                  src={GoogleChatIcon}
                  className="w-10"
                  alt="google chat icon"
                />
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default DashboardCard;
