import React, { useCallback, useEffect, useState } from 'react'
import { TableSpinner } from '../UI/Spinner'
import NoTableData from '../UI/NoTableData'
import TableHead from '../Table/TableHead'
import { FORM_TABLE_HEADING } from '../../utils/tableHeadings'
import dayjs from 'dayjs'
import { getFormsByNumber } from '../../api'

const FormSection = ({ clientId, number }) => {

  const [forms, setForms] = useState([])
	const [spinner, setSpinner] = useState(false)

  const fetchData = useCallback(async() => {
    try {
      setSpinner(true)
      const res = await getFormsByNumber(clientId, number)
      setForms(res.data.data)
    } finally {
      setSpinner(false)
    }

	}, [number])

	useEffect(() => {
		fetchData()

	}, [fetchData])

  return (
    <>
      {forms.length > 0 && <section className="bg-white overflow-x-auto slider py-6">
        <table className="w-full min-w-[1024px] table-fixed overflow-x-auto overflow-hidden slider mt-4">
          <TableHead headings={FORM_TABLE_HEADING} />
          <tbody className="text-gray-600 text-sm font-400">
            {spinner ? <TableSpinner tds={FORM_TABLE_HEADING.length} /> : (
              forms.length === 0 ? <NoTableData /> :
              forms.map((data) => (
                  <tr key={data.id} className="border-b text-left border-gray-200">
                    <td className="p-3">{dayjs(data.dateCreated).format("YYYY-MM-DD hh:mm:ss")}</td>
                    <td className="p-3">{data.firstName}</td>
                    <td className="p-3">{data.lastName}</td>
                    <td className="p-3 break-words">{data.email}</td>
                    <td className="p-3 break-words">{data.description}</td>
                    <td className="p-3 break-words">{data.address}</td>
                    <td className="p-3">{data.city}</td>
                    <td className="p-3">{data.state}</td>
                    <td className="p-3">{data.zipCode}</td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
      </section>} 
    </>
  )
}

export default FormSection
