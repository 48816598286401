import React from "react";
import downIcon from "../../assets/svgs/downIcon.svg";

const DropDown = ({ openDropDown, displayCurrentValue, onClick, show, data }) => {
	return (
		<div className="flex items-center ml-4 z-10">
			<p className="mr-2 text-base text-gray-500 font-400">Show</p>
			<div
				onClick={openDropDown}
				className="w-20 h-8 border border-gray-300 rounded-md flex justify-between px-3 relative items-center cursor-pointer"
			>
				<p className="font-400">{displayCurrentValue}</p>
				<img
					src={downIcon}
					className={"h-full w-[16px] transform " + (show && "rotate-180")}
					alt="down icon"
				/>
				{show && (
					<div className="absolute left-0 bg-white text-center top-9 border border-gray-300 rounded-md w-full">
						<ul className="list-none">
							{data.map((value) => (
								<li
									onClick={() => onClick(value)}
									key={value}
									value={value}
									className="py-1 border-b cursor-pointer font-400"
								>
									{value}
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</div>
	);
};

export default DropDown;
