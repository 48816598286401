import React from "react"

const Spinner = ({ borderColor, borderWidth, height, width }) => {
  return (
    <div
      className={`loader ease-linear mx-auto rounded-full border-${borderColor} border-${borderWidth} ${width} ${height}`}>
    </div>
  );
};

export default Spinner

export const MediumSpinner = () => {
  return (
    <div className="loader ease-linear mx-auto rounded-full border-blue-600 border-2 w-10 h-10"/>
  )
}

export const TableSpinner = ({ tds = 1 }) => {

  const td = []

  for (let i = 0; i < tds; i++) {
    td.push(
      <td key={i} className='p-2'>
        <div className='h-6 rounded bg-slate-200'/>
      </td>
    )
  }

  return (
    <>
      <tr className='animate-pulse border-b border-gray-200 py-2'>
        {td}
      </tr>
      <tr className='animate-pulse border-b border-gray-200 py-2'>
        {td}
      </tr>
    </>
  )
}
