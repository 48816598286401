import React, { useCallback, useEffect, useState } from "react";
import IntegrationCard from "../Card/IntegrationCard";
import { Phone } from "@mui/icons-material";
import { useAuth } from "../../context/AuthContext";
import { getProfile } from "../../services/FirebaseFirestore";

const PhoneCard = () => {
	const { currentUser } = useAuth();
	const [profile, setProfile] = useState(null);

	const getUserProfile = useCallback(async () => {
		try {
			const profile = await getProfile(currentUser.displayName);
			setProfile(profile);
		} catch (err) {
			console.error(err);
		}
	}, [currentUser.displayName]);

	useEffect(() => {
		getUserProfile();
	}, [getUserProfile]);

	return (
		<IntegrationCard
			icon={<Phone sx={{ width: "100%", height: "100%" }} />}
			title={"Phone"}
			description={`Connect incoming business calls to your business phone number by forwarding calls to your dedicated SnapPicFix phone number: ${
				profile?.twilioNumber ?? ""
			}`}
			disabled={true}
		/>
	);
};

export default PhoneCard;
