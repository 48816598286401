import axios from "axios";
import { API_URL } from "../utils/url-constants";

export const getImagesByNumber = async (clientId, number) => {
	const res = await axios.get(`${API_URL.SPF_API}/api/v1/images/${clientId}?number=${number}`);
	return res.data;
};

// Get forms by number and clientId
export const getFormsByNumber = async (clientId, number) => {
	return await axios.get(`${API_URL.SPF_API}/api/v1/forms/${clientId}?number=${number}`);
};

// Send outbound messaage and save message by number and clientId
export const sendOutboundMsg = async (data) => {
	const res = await axios.post(`${API_URL.SPF_API}/api/v1/twilio/send`, data);
	return res.data;
};

// Get messages by number and clientId
export const getMessagesByNumber = async (clientId, number) => {
	return await axios.get(
		`${process.env.REACT_APP_SPF_BOOKING}/api/v1/notes?clientId=${clientId}&number=${number}`
	);
};

// Get all logs activity
export const getLogs = async (clientId) => {
	return await axios.get(`${API_URL.SPF_API}/api/v1/logs/${clientId}`);
};

// Get analytics
export const getAnalytics = async (clientId) => {
	return await axios.get(`${API_URL.SPF_API}/api/v1/analytics/${clientId}`);
};

// Get messages by number and clientId
export const getVoicemailsByNumber = async (clientId, number) => {
	return await axios.get(`${API_URL.SPF_API}/api/v1/voicemails/${clientId}?number=${number}`);
};

// Register user
export const registerBrand = async (data) => {
	return await axios.post(`${API_URL.SPF_API}/api/v1/auth/register`, data);
};

// Delete voicemail by id
export const deleteVoicemailById = async (clientId, id) => {
	return await axios.post(`${API_URL.SPF_API}/api/v1/voicemail/delete`, { clientId, id });
};
