import React from "react"
import { useHistory } from "react-router-dom"
import Cross from "../../assets/svgs/cross.svg"
import { useAuth } from "../../context/AuthContext"
import { logoutUser } from '../../services/Auth/Auth'
import SidebarTab from "../Sidebar/SidebarTab"
import BarChartIcon from '@mui/icons-material/BarChart'
import ContactsIcon from '@mui/icons-material/Contacts'
import LogoutIcon from '@mui/icons-material/Logout'
import TuneIcon from '@mui/icons-material/Tune'

const Sidebar = ({ show, closeSideDrawer }) => {

	const { setCurrentUser, currentUser } = useAuth()
	const history = useHistory()
	const sidebarTitle = !currentUser.brandName ? currentUser.displayName : currentUser.brandName

	let joinClasses = [
		" transform -translate-x-full xl:translate-x-0 transition duration-500 ease-in",
	];
	if (show) {
		joinClasses = ["  transform translate-x-0 transition duration-500 ease-in"];
	}

	const logout = async () => {
		await logoutUser();
		setCurrentUser(null)
		history.replace("/");
	};

	return (
		<>
		{show && <div onClick={closeSideDrawer} className="fixed overflow-hidden inset-0 bg-black opacity-70 h-full w-full z-[41]"></div>}
		<aside
			className={
				joinClasses.join(" ") +
				" z-[42] fixed flex flex-col min-h-screen px-4 text-gray-900 transition duration-500 ease-in overflow-hidden h-full font-500 w-[280px] border-r p-4 bg-white"
			}
		>
			<div className="absolute top-3 right-4">
				<img
					src={Cross}
					onClick={closeSideDrawer}
					className="cursor-pointer w-7 h-7 xl:hidden p-1 rounded-full"
					alt="crosss icon"
				/>
			</div>
			<div className="flex flex-col justify-center items-center border-b mt-3">
				<div className="my-6 bg-[rgba(145,158,171,0.12)] flex justify-start items-center p-4 rounded-xl w-full overflow-hidden">
					<div className='w-1/4'>
						<div className="w-10 h-10 rounded-full overflow-hidden">
							<img
								src={currentUser.photoURL}
								alt='logo' className="object-contain w-full"
							/>
						</div>
					</div>
					<p className='ml-2 w-3/4 break-words'>{sidebarTitle}</p>
				</div>
			</div>
			<div className="mt-4 h-full flex flex-col justify-between">
				<ul>
					<SidebarTab icon={<BarChartIcon />} to={'dashboard'} text={'Dashboard'} />
					<SidebarTab icon={<ContactsIcon />} to={'call_logs'} text={'Activity Log'} />
					<SidebarTab icon={<TuneIcon />} to={'integrations'} text={'Integrations'} />
				</ul>
				<ul className='border-t'>
					<button
						className='flex items-center w-full rounded p-4 text-base'
						onClick={logout}
					>
						<LogoutIcon />
						<p className='ml-2 mx-0'>Logout</p>
					</button>
				</ul>
			</div>
		</aside>
		</>
	);
};

export default Sidebar;
