import { Paper } from "@mui/material";
import React from "react";
import { style } from "./index.style";
import BaseBox from "../../libs/BaseBox/BaseBox";

const QrCode = ({ qrCode }) => {
	return (
		<Paper sx={style.container}>
			<BaseBox component="img" sx={style.image} src={qrCode} />
		</Paper>
	);
};

export default QrCode;
