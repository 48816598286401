import dayjs from 'dayjs'

const getDate = () => dayjs().format()

const generateClientId = (location, brand) => {
  if (location) {
		return `${brand.replace(/[^a-z]/gi, '').toLowerCase()}_${location.replace(/[^a-z]/gi, '').toLowerCase()}`
	} else {
		return  brand.replace(/[^a-z]/gi, '').toLowerCase()
	}
}

const cleanNumber = (number) => !number ? number : number.replace(/\D/g, '')

export {
  getDate,
  generateClientId,
  cleanNumber
}