import { getDownloadURL, uploadBytes } from "firebase/storage"

// Uploading file to firebase/storage
export const uploadFile = async (fileRef, value) => {

  try {
    await uploadBytes(fileRef, value)
    const res = await getFileUrl(fileRef)
    return { data: { url: res }, status: true }
  } catch (error) {
    return { data: null, status: false }
  }
};

// Getting logoURL of uploaded file
export const getFileUrl = async (fileRef) => {
  return await getDownloadURL(fileRef)
}
