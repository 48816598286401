import axios from 'axios'
import { API_URL } from '../utils/url-constants'
import { getDate } from '../utils/helpers'
import { db } from '../firebase'
import { addDoc, collection, doc, getDoc } from 'firebase/firestore'


/**
 * Mannual registeration of jobber
 * @param {Object} data 
 * @returns response
 */
export const registerJobberUser = async (data) => {

  try {
    const res = await addDoc(collection(db, 'jobber_users'), {
      client_id: process.env.REACT_APP_JOBBER_CLIENT_ID,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone_number: data.phoneNumber,
      business_home: data.businessHome,
      website: data.website,
      refresh_token: null,
      client_secret: process.env.REACT_APP_JOBBER_APP_SECRET,
      brand_name: null,
      brand_id: null,
      status: false,
      created_at: getDate(),
      updated_at: getDate()
    })

    return {
      status: true,
      id: res.id 
    }

  } catch(error) {
    console.error(error)
    return {
      status: false,
      message: 'Fail to connect jobber'
    }
  }
}


/**
 * @param {String} client_id
 * @returns Jobber status (true or false)
 */
export const jobberUserStatus = async (client_id) => {

  try {
    const docRef = doc(db, 'client_info', client_id)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const { jobber_user_id, jobber_status } = docSnap.data()
      if (!jobber_status) return false

      const docRef = doc(db, 'jobber_users', jobber_user_id)
      const document = await getDoc(docRef)

      if (document.exists()) {
        return document.data().status
      } else {
        return false
      }
    }

  } catch (err) {
    console.error(err)
  }
}

/**
 * Registering user when directly came from jobber app
 * @param {String} auth_code 
 * @returns response
 */
export const createJobber = async (auth_code) => {
  return await axios.post(`${API_URL.SPF_DASH_BACKEND}/jobber/create`,
  { authorization_code: auth_code },
  {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}


/**
 * Verifying manual registration of user after redirection
 * @param {String} auth_code 
 * @param {String} secret 
 * @returns response
 */
export const verifyManualRegisteration = async (auth_code, secret) => {
  return await axios.post(`${API_URL.SPF_DASH_BACKEND}/jobber/verify/manually`,
  {
    authorization_code: auth_code,
    state: secret
  }, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}


export const disconnectJobberIntegration = async (clientId) => {
  try {
    const res = await axios.post(`${API_URL.SPF_DASH_BACKEND}/jobber/disconnect`,
    { client_id: clientId })
    return { message: res.data.message, status: true }

  } catch (error) {
    return { message: 'Failed to disconnect try again later', status: false }
  }
}


export const jobberAuthorizeUrl = (state) => {

  const redirect_uri = 'https://master.d58ete4q4i2lh.amplifyapp.com/jobber_auth'

  return `${API_URL.JOBBER_OAUTH}/authorize?client_id=${process.env.REACT_APP_JOBBER_CLIENT_ID}&redirect_uri=${redirect_uri}&state=${state}`
}
