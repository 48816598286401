import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { MediumSpinner } from '../components/UI/Spinner'
 
const PrivateRoute = ({ children, ...rest }) => {

  const { currentUser, loading } = useAuth()

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return loading ? <div className='min-h-screen grid place-items-center'><MediumSpinner /></div>
        :
        (currentUser ? children : <Redirect to={{ pathname: '/', state: { from: location }}} />)
      }}
    />
  )
}
 
export default PrivateRoute
