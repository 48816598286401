import React from "react";
import { Paper } from "@mui/material";
import { style } from "./bannerStyle";
import Link from "@mui/material/Link";

const Banner = () => {
  return (
    <Paper sx={style.banner}>
      Your trial has expired. Please visit
      <Link sx={style.link} href="https://www.snappicfix.com/subscribe">
        https://www.snappicfix.com/subscribe
      </Link>
      to update your subscription.
    </Paper>
  );
};

export default Banner;
