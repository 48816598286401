import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const SidebarTab = ({ to, text, icon }) => {

  const [active, setActive] = useState('')

  const location = useLocation()

  useEffect(() => {
		let path = location.pathname
    path = path.replace('/', '')
		setActive(path)

	}, [location])

  return (
    <Link to={'/'+to} className={'p-4 w-full rounded text-base flex items-center justify-start ' + 
      (active === to ? 'bg-[#274fc72f] text-[#274FC7]' : 'text-black')}
    >
      {icon} <span className='ml-4'>{text}</span>
    </Link>
  )
}

export default SidebarTab
