import React from "react";
import IconButton from "@mui/material/IconButton";

const BaseIconButton = ({ children, onClick, ...props }) => {
	return (
		<IconButton aria-label="delete" {...props} onClick={onClick}>
			{children}
		</IconButton>
	);
};

export default BaseIconButton;
