import React from "react";
import { useAuth } from "../context/AuthContext";
import Layout from "../components/UI/Layout";
import backArrow from "../assets/svgs/backArrow.svg";
import { useHistory, useParams } from "react-router-dom";
import ImageSection from "../components/NumberDetails/ImageSection";
import FormSection from "../components/NumberDetails/FormSection.jsx";
import VoicemailSection from "../components/NumberDetails/VoicemailSection.jsx";
import NumberLogs from "../components/NumberDetails/NumberLogs.jsx";
import MessageSection from "../components/Note/NoteSection.js";

const NumberPage = () => {
	const history = useHistory();
	const params = useParams();

	const { currentUser } = useAuth();

	const backToDashboard = () => history.push("/call_logs");

	return (
		<Layout>
			<div className="px-4 xl:px-10 mt-10 py-12 border rounded shadow-md">
				<div className="flex mb-4 cursor-pointer" onClick={backToDashboard}>
					<img src={backArrow} alt="back arrow" />
					<span className="ml-2 text-gray-400 font-300">Back to callLogs</span>
				</div>

				<h1 className="text-4xl font-600">{params?.number}</h1>

				{/* Forms Section */}
				<FormSection clientId={currentUser?.displayName} number={params.number} />

				{/* Voicemails Section */}
				<VoicemailSection number={params.number} clientId={currentUser?.displayName} />

				{/* Logs Section */}
				<NumberLogs clientId={currentUser.displayName} number={params?.number}>
					<MessageSection clientId={currentUser.displayName} number={params?.number} />
				</NumberLogs>

				{/* Images Section */}
				<ImageSection clientId={currentUser.displayName} number={params?.number} />
			</div>
		</Layout>
	);
};

export default NumberPage;
