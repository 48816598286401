import React, { useCallback, useEffect, useState } from "react";
import Loader from "../../components/UI/Loader";
import { useLocation } from "react-router-dom";
import { registerBrand } from "../../api/index";
import { style } from "./index.style";
import BaseBox from "../../libs/BaseBox/BaseBox";
import ConnectContainer from "../../containers/connect";

const Connect = () => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	const { search } = useLocation();
	const params = new URLSearchParams(search);

	const register = useCallback(async () => {
		const data = {};
		for (const [key, value] of params) {
			data[key] = value?.replace(/\\/g, "")?.replace(/"/g, "");
		}
		try {
			const res = await registerBrand(data);
			setData(res.data.data);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		register();
	}, [register]);

	if (loading) {
		return (
			<BaseBox sx={style.page}>
				<Loader size={40} />
			</BaseBox>
		);
	}

	return (
		<BaseBox sx={style.page}>
			<ConnectContainer data={data} />
		</BaseBox>
	);
};

export default Connect;
