export const FORM_TABLE_HEADING = [
  { heading: 'Date', class: 'table-heading w-[12%]' },
  { heading: 'First Name', class: 'table-heading w-[10%]' },
  { heading: 'Last Name', class: 'table-heading w-[10%]' },
  { heading: 'Email', class: 'table-heading w-[15%]' },
  { heading: 'Description', class: 'table-heading w-[20%]' },
  { heading: 'Address', class: 'table-heading w-[10%]' },
  { heading: 'City', class: 'table-heading w-[10%]' },
  { heading: 'State', class: 'table-heading w-[10%]' },
  { heading: 'Zip Code', class: 'table-heading w-[8%]' },
]
