import { Modal, Typography } from "@mui/material";
import React from "react";
import BaseBox from "../BaseBox/BaseBox";
import { style } from "./index.style";

const BaseModal = ({ open, onClose, children, title }) => {
	return (
		<Modal open={open} onClose={onClose}>
			<BaseBox sx={style.container}>
				<Typography variant="h4" textAlign="center">
					{title}
				</Typography>
				{children}
			</BaseBox>
		</Modal>
	);
};

export default BaseModal;
