import React, { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase"
import { doc, getDoc } from "firebase/firestore"

const Context = createContext();

const AuthProvider = ({ children }) => {

	const [currentUser, setCurrentUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [phoneNumber, setPhoneNumber] = useState("");

	const getUserInfo = async (clientId) => {
		const docRef = doc(db, 'client_info', clientId)
		const docSnap = await getDoc(docRef)

		const data = docSnap.exists() ? docSnap.data() : null
		return data
	}

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user) {
				const { email, uid, photoURL, displayName } = user
				const data = await getUserInfo(displayName)
				const brandName = data.brand
				const number = data.twilioNumber
				const status = data.status ?? "Active"
				setCurrentUser({ email, uid, number, photoURL, displayName, clientId, brandName,status })
				setLoading(false)
			} else {
				setCurrentUser(null)
				setLoading(false);
			}
		});

		return () => {
			unsubscribe();
		};
	}, []);

	const clientId = currentUser?.displayName

	const values = {
		currentUser,
		setCurrentUser,
		loading,
		phoneNumber,
		setPhoneNumber,
		clientId,
	};
	

	return (
		<Context.Provider value={values}>
			{children}
		</Context.Provider>
	)
};

export default AuthProvider

export const useAuth = () => {
	return useContext(Context)
};
