import React, { useCallback, useEffect, useState } from "react";
import { deleteVoicemailById, getVoicemailsByNumber } from "../../api";
import dayjs from "dayjs";
import SectionHeading from "../UI/SectionHeading";
import { DeleteFilledIcon } from "../../assets";
import BaseBox from "../../libs/BaseBox/BaseBox";
import BaseIconButton from "../../libs/BaseIconButton/BaseIconButton";
import BaseButton from "../../libs/BaseButton/BaseButton";
import BaseModal from "../../libs/BaseModal/BaseModal";
import { style } from "./Voicemail";
import { toast } from "react-toastify";

const VoicemailSection = ({ number, clientId }) => {
	const [voicemails, setVoicemails] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModal] = useState(false);

	const getVoicemails = useCallback(async () => {
		const res = await getVoicemailsByNumber(clientId, number);
		setVoicemails(res.data.data);
	}, [number]);

	useEffect(() => {
		getVoicemails();
	}, [getVoicemails]);

	const toggleModal = () => {
		setIsModal(true);
	};

	const deleteVoicemail = async (id) => {
		setIsLoading(true);
		try {
			await deleteVoicemailById(clientId, id);
			const res = await getVoicemailsByNumber(clientId, number);
			setVoicemails(res.data.data);
		} catch (error) {
			toast.error("Failed to delete voicemail");
		} finally {
			setIsLoading(false);
			setIsModal(false);
		}
	};

	const closeModal = () => {
		setIsModal(false);
	};

	return (
		<>
			{voicemails.length > 0 && (
				<section className="w-full py-6">
					<SectionHeading heading="Voicemails" />
					<div className="mt-2">
						{voicemails.map((voicemail) => (
							<div key={voicemail.id} className="my-4 text-base">
								<BaseBox display="flex" alignItems="center" justifyContent="space-between">
									<p className="text-gray-400 m-0 font-400">
										{dayjs(voicemail.dateCreated).format("YYYY-MM-DD hh:mm:ss")}
									</p>
									<BaseIconButton onClick={toggleModal}>
										<DeleteFilledIcon />
									</BaseIconButton>
									<BaseModal
										open={isModalOpen}
										title={"Are you sure you want to delete this voicemail?"}
										onClose={closeModal}
									>
										<BaseBox sx={style.modalButtons}>
											<BaseButton
												text="Cancel"
												sx={style.button}
												onClick={closeModal}
												variant="outlined"
											/>
											<BaseButton
												text="Delete"
												sx={style.button}
												onClick={() => deleteVoicemail(voicemail.id)}
												color="error"
												isLoading={isLoading}
												disabled={isLoading}
											/>
										</BaseBox>
									</BaseModal>
								</BaseBox>
								<p className="text-gray-400 font-400 mt-[5px] break-words w-full">
									{voicemail.message}
								</p>
								<audio controls className="mt-[10px] w-full " src={voicemail.voicemail}></audio>
							</div>
						))}
					</div>
				</section>
			)}
		</>
	);
};

export default VoicemailSection;
