import React, { useState } from "react";
import Hamburger from "../../assets/svgs/hamburger.svg";
import Cookies from "js-cookie";
import Camera from "../../assets/images/Camera.png";
import { useHistory } from "react-router-dom";
import { logoutUser } from '../../services/Auth/Auth'
import MenuCard from '../Card/MenuCard'
import { useAuth } from "../../context/AuthContext";

const Navbar = ({ openSideDrawer }) => {

	const { currentUser } = useAuth()

	const history = useHistory()

	const [isMenu, setIsMenu] = useState(false)

	const logout = () => {
		Cookies.remove("access_token")
		logoutUser()
		history.replace("/")
	};

	const toggleMenu = () => {
		setIsMenu(!isMenu)
	}

	return (
		<header className="w-full z-40 sticky h-16 top-0 bg-white shadow-navbarShadow font-400">
			<nav className="w-full h-full xl:flex justify-between items-center px-10 relative">
				<div className="flex h-full items-center">
					<div className="xl:hidden cursor-pointer w-12">
						<img onClick={openSideDrawer} src={Hamburger} alt="Hamburger icon" />
					</div>
					<div className="flex items-center justify-center w-full">
						<img src={Camera} alt="Camera icon" />
						<div className="ml-3 text-base text-navbarHeadingColor">
							<h2 className="m-0 font-500 text-xl"></h2>
						</div>
					</div>
				</div>

				<button
					className='w-10 h-10 rounded-full overflow-hidden hidden xl:block'
					onClick={toggleMenu}
				>
					<img
						src={currentUser.photoURL} alt='Profile logo'
						className='object-contain w-full'
					/>
				</button>

				{isMenu && <MenuCard onLogout={logout} />}
			</nav>
		</header>
	);
};

export default Navbar;
