import React from "react"
import downIcon from "../../assets/svgs/downIcon.svg"

const DropDown = ({ label, dropDownHandler, currentValue, onClick, isOpen, data }) => {
	return (
		<div className="z-10 relative mb-6">
			<p className="input-label">{label}</p>
			<div
				onClick={dropDownHandler}
				className={"h-12 flex border-gray-300 justify-between px-3 relative items-center cursor-pointer bg-white " + ((isOpen ? "rounded-b-none rounded-t-md border-r border-l border-t border-b-0" : "rounded-md border"))}
			>
				<p className="font-400">{currentValue}</p>
				<img
					src={downIcon}
					className={"h-full w-[16px] transform " + (isOpen && "rotate-180")}
					alt="arrow icon"
				/>
			</div>
      {isOpen && (
        <div className={"absolute left-0 bg-white text-center top-20 border border-gray-300  w-full overflow-hidden " + (isOpen && "rounded-t-none rounded-b-md") }>
          <ul className="list-none">
            {data.map((value) => (
              <li
                onClick={() => onClick(value)}
                key={value}
                value={value}
                className="py-1 border-b cursor-pointer font-400 text-left px-4 hover:bg-slate-100"
              >
                {value}
              </li>
            ))}
          </ul>
        </div>
			)}
		</div>
	)
}

export default DropDown
