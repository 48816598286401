import React from 'react'

const Page404 = () => {
  return (
    <main className="h-screen w-full flex flex-col justify-center items-center">
      <h1 className="text-8xl font-800 text-[#1A2238]">404</h1>
      <h2 className="text-4xl font-600 text-[#1A2238] mt-3">
        Page Not Found
      </h2>
      <p className='text-[#1A2238] font-400 mt-3'>
        The page you are looking for might have been removed had its name changed or is temporarily unavailable.
      </p>
  </main>
  )
}

export default Page404
