import React from 'react'

const TextArea = ({ label, onChange, value, name, placeholder, displayLabel = true, length }) => {
  return (
    <div className="flex flex-col items-start mb-6">
      {displayLabel && <label htmlFor={name} className="input-label">
				{label}
			</label>}
      <textarea
        className="resize-none w-full h-24 rounded-2xl bg-transparent ring-[1.5px] ring-white focus:outline-none focus:ring-[1.5px] focus:ring-borderColor px-4 py-2 text-white font-400 text-base placeholder-slate-400 block overflow-y-auto "
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        id={name}
      >
      </textarea>
      <p className='w-full text-right text-white font-400 mt-1'>{length}/160</p>
    </div>
  )
}

export default TextArea