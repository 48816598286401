import { Button, CircularProgress } from "@mui/material";
import React from "react";

const BaseButton = ({
	variant = "contained",
	text,
	onClick,
	isLoading,
	color = "primary",
	...otherProps
}) => {
	return (
		<Button variant={variant} onClick={onClick} color={color} {...otherProps} disableElevation>
			{isLoading ? <CircularProgress size={20} sx={{ color: "gray" }} /> : text}
		</Button>
	);
};

export default BaseButton;
