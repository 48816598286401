export const style = {
	container: {
		padding: "16px",
		display: "flex",
		justifyContent: "center",
		width: { sm: "300px" },
		marginX: { sm: "auto" },
	},
	image: { width: { xs: "100%", sm: "300px" } },
};
