import { SIGNUP_ACTION } from "../actions/Signup"

export const initialState = {
  username: '',
  email: '',
  password: '',
  replyEmail: '',
  areaCode: '',
  officeNumber: '',
  toEmail: '',
  toEmail1: '',
  phoneProvider: '',
  gbmAgent: false,
  brand: '',
  location: '',
  subject: '',
  timeZone: '',
  answeringNumber : '',
  forwardNumber: '',
  customerUrl: '',
  jobUrl: '',
  smsBody: '',
  angiEmail: '',
  ttackEmail: '',
  ttackMsg: '',
  confirmationText: '',
  automated: true,
  unsolicited: true,
  noResponseNotification: false,
  managementOther: '',
  accountSid: '',
  authToken: '',
  flowtype :	'flowType6',
  twilioNumber: '',
  crmType: 'NONE'
}

export const reducer = (state, action) => {
  switch(action.type) {
    case SIGNUP_ACTION.INPUT_HANDLER:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      }

    case SIGNUP_ACTION.CHECKBOX_HANDLER:
      return {
        ...state,
        [action.payload.key]: action.payload.checked
      }
    case SIGNUP_ACTION.RESET_FORM:
      return initialState
    default:
      return state
  }
}