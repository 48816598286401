import React from 'react'

const ImageCard = ({ href, src }) => {
  return (
    <div className="relative group w-40 h-40 rounded-lg overflow-hidden shadow-md">
      <div className="z-50 opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out cursor-pointer absolute bottom-0 from-black/100 to-transparent bg-gradient-to-t inset-x-0 text-white">
        <a
          href={href}
          target="_blank"
          className="p-2 block text-sm bg-main w-max rounded-tr-lg interFont"
          rel="noreferrer"
        >
          See image
        </a>
      </div>
      <img
        src={src}
        className="w-full transition object-cover duration-300 ease-in-out hover:opacity-70"
        alt="photos"
      />
    </div>
  )
}

export default ImageCard