import React from "react";
import Layout from "../components/UI/Layout";
import PageTitle from "../components/UI/PageTitle";
import JobberCard from "../components/Integration/JobberCard";
import PhoneCard from "../components/Integration/PhoneCard";

const IntegrationPage = () => {
	return (
		<Layout>
			<PageTitle title={"Integrations"} />
			<div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-8">
				<JobberCard />
				<PhoneCard />
			</div>
		</Layout>
	);
};

export default IntegrationPage;
