import { COLOR } from "../utils/colors.js";
import JobberIcon from "./images/JobberIcon.jsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import PhoneIcon from "@mui/icons-material/Phone";

export const JobberIconOutline = (props) => <JobberIcon {...props} />;
export const MoreVertOutlineIcon = (props) => <MoreVertIcon color={COLOR.black} {...props} />;
export const DeleteFilledIcon = (props) => <DeleteIcon {...props} />;
export const PhoneFilledIcon = (props) => <PhoneIcon {...props} />;
