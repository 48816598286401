import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../context/AuthContext";
import search_icon from "../assets/svgs/search_icon.svg";
import { useHistory, Link } from "react-router-dom";
import DropDown from "../components/UI/showDropDown";
import moment from "moment";
import { TableSpinner } from "../components/UI/Spinner";
import weekend from "../assets/images/weekend-icon.png";
import Layout from "../components/UI/Layout";
import { LOGS_ICON, tableRowValues } from "../utils/Consts";
import DateSelector from "../components/Date";
import PageTitle from "../components/UI/PageTitle";
import { getLogs } from "../api";
import dayjs from "dayjs";

const CallLogs = () => {
	const [rows, setRows] = useState(10);
	const [selectRow, setSelectRow] = useState("All");
	const [datePicker, setDatePicker] = useState(false);
	const [dateValue, setDateValue] = useState({ startDate: "", endDate: "" });
	const [isDropDownOpen, setIsDropDownOpen] = useState(false);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const [callLogData, setCallLogData] = useState([]);

	const { currentUser } = useAuth();
	const history = useHistory();

	const mobileStyles = {
		display: "flex",
		flexDirection: 'column'
	};

	const loadData = useCallback(async () => {
		try {
			setLoading(true);
			const res = await getLogs(currentUser?.displayName);
			setRows(res.data?.data?.length);
			setSelectRow("All");
			setCallLogData(res.data.data);
		} catch (error) {
			console.log('Load Data', error);
			console.log('Display', currentUser?.displayName)
		} finally {
			setLoading(false);
		}
	}, [currentUser?.displayName]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	const datehandler = (ranges) => {
		setDateValue({
			...dateValue,
			startDate: ranges.selection.startDate,
			endDate: ranges.selection.endDate,
		});
		setDatePicker(false);
	};

	const cancelDateRange = () => {
		setDateValue({
			...dateValue,
			startDate: "",
			endDate: "",
		});
		setDatePicker(false);
	};

	const rowHandleClick = (num) => {
		const number = num?.toString();
		history.push(`/call_logs/${number.slice(-10)}`);
	};

	const setTableRows = (row) => {
		if (row === "All") {
			setRows(callLogData.length);
			setSelectRow("All");
		} else {
			setSelectRow(row);
			setRows(row);
		}
	};

	const searchHandler = (e) => setSearch(e.target.value);

	const filteredData = (val) => {
		if (dateValue.startDate === "" && dateValue.endDate === "" && search === "") {
			return val;
		}
		if (dateValue.startDate !== "" && dateValue.endDate !== "" && search === "") {
			return (
				val.dateCreated >= moment(dateValue.startDate).format("YYYY-MM-DD") &&
				val.dateCreated <= moment(dateValue.endDate).format("YYYY-MM-DD")
			);
		}
		if (dateValue.startDate === "" && dateValue.endDate === "" && search !== "") {
			return val.number.includes(search);
		}
		return (
			val.dateCreated >= moment(dateValue.startDate).format("YYYY-MM-DD") &&
			val.dateCreated <= moment(dateValue.endDate).format("YYYY-MM-DD") &&
			val.number.includes(search)
		);
	};

	const DateCol = ({ dateCreated }) => {
		return (
			<>
				{dayjs(dateCreated).format("ddd MMM D h:mm A")}
				{(dayjs(dateCreated).format("ddd") === "Sun" ||
					dayjs(dateCreated).format("ddd") === "Sat") && (
						<img src={weekend} alt="weekend" className="w-7 h-7 ml-1" />
					)}
			</>
		);
	};

	return (
		<Layout>
			<PageTitle title="Call and Text Activity" />
			<div>
				<div className="bg-white slider overflow-x-auto border shadow-md rounded-md py-12 px-4 xl:px-10 min-h-[580px]">
					<h2 className="text-2xl font-600 md:text-4xl">Leads 💰</h2>

					<div className="mt-4 flex flex-col md:flex-row justify-between items-start md:items-center">
						<div className="flex">
							<div className="w-[217px] focus:ring-1 rounded-md focus:ring-blue-600 flex justify-between ring-1 ring-gray-300 px-2">
								<input
									type="text"
									className="h-8 px-2 border-none outline-none shadow-none w-full "
									placeholder="Search"
									onChange={searchHandler}
								/>
								<img src={search_icon} className="" alt="serach-icon" />
							</div>
						</div>
						<div className="flex mt-4 md:mt-0">
							<DropDown
								show={isDropDownOpen}
								onClick={(value) => setTableRows(value)}
								displayCurrentValue={selectRow}
								openDropDown={() => setIsDropDownOpen(!isDropDownOpen)}
								data={tableRowValues}
							/>

							<DateSelector
								onClick={() => setDatePicker(!datePicker)}
								startDate={dateValue.startDate}
								endDate={dateValue.endDate}
								showDate={datePicker}
								onChange={datehandler}
								cancelDateRange={cancelDateRange}
							/>
						</div>
					</div>


					{/* Mobile Responsive Table*/}
					<table className="w-full table-respon overflow-x-auto overflow-hidden mt-9">
						<thead className="invisible sm:visible">
							{/*flex flex-col sm:flex-row justify-between items-start */}
							<tr className="hidden sm:flex sm:justify-between border-b-2 border-gray-400 text-sm leading-normal">
								<th className="table-heading w-24">Date</th>
								<th className="table-heading w-24">Number</th>
								<th className="table-heading w-24">Status</th>
							</tr>
						</thead>
						<tbody className="text-gray-600 text-sm font-light w-full">
							{loading ? (
								<TableSpinner tds={3} />
							) : callLogData.length === 0 ? (
								<tr className="relative">
									<td className="absolute left-1/2 transform -translate-x-1/2 font-400 text-black text-lg">
										No data
									</td>
								</tr>
							) : (
								callLogData
									.filter(filteredData)
									.slice(0, rows)
									.map((data, index) => {
										return (
											<tr key={index} className="flex sm:none justify-normal sm:justify-between w-full border-b border-gray-200 cursor-pointer">
												{/*Date Column for bigger screens that hides on small screens */}
												<td
													onClick={() => rowHandleClick(data.number)}
													className="hidden sm:flex table-data whitespace-nowrap p-2"
												>
													<div className="items-center">
														<DateCol dateCreated={data.dateCreated} />
													</div>
												</td>

												{/*Mobile UI */}
												<td onClick={() => rowHandleClick(data.number)} className="flex sm:none justify-between	sm:justify-start w-full sm:w-auto table-data p-2">
													<div className="flex sm:none justify-between items-center">
														{data.type && LOGS_ICON[data.type]}
														<div className="items-center pl-3">
															{/*Number for both Mobile UI and bigger screens. The number gets turned into block display for bigger screens */}
															<span className="none sm:block font-black sm:font-normal py-2">
																<span>{data.number}</span>
															</span>
															{/*Number for both Mobile UI and bigger screens. The number gets turned into block display for bigger screens */}
															<div className="flex sm:hidden py-2">
																{data.status}
															</div>
														</div>
													</div>
													{/*Date for Mobile UI. Gets hidden on Bigger Screen */}
													<span className="block sm:hidden"><DateCol dateCreated={data.dateCreated} /></span>
												</td>

												{/*Status for bigger screens that hides on small screens  */}
												<td onClick={() => rowHandleClick(data.number)} className="hidden sm:flex table-data p-2">
													{data.status}
												</td>
											</tr>
										);
									})
							)}
						</tbody>
					</table>
					{/* ^ Mobile Responsive Table ^ */}

				</div>
			</div>
		</Layout>
	);
};

export default CallLogs;
