import React from "react"
import { ReactComponent as StarSvg } from "../../assets/svgs/star.svg"

const Input = ({ 
	type,
	value,
	onChange,
	name,
	placeholder,
	label,
	displayLabel = true,
	required = false,
	icon,
	displayIcon = false
}) => {

	return (
		<div className="mb-6">
			<div className="flex">
				{
					displayLabel && 
					<label className="input-label">
						{label}
					</label>
				}
				{ required && <StarSvg /> }
			</div>
			<div className="relative">
				{
					displayIcon &&
					<div className="absolute left-4 h-full flex items-center">
						<div className="w-8 h-8">{icon}</div>
					</div>
				}
				<input
					type={type}
					className={"w-full bg-transparent ring-[1.5px] ring-white rounded-2xl h-12 text-base font-normal focus:outline-none focus:ring-[1.5px] focus:ring-borderColor placeholder-slate-400 text-white font-400 " + ( displayIcon ? "pl-16 pr-4" : "px-4" )
					}
					value={value}
					name={name}
					onChange={onChange}
					placeholder={placeholder}
				/>
			</div>
		</div>
	)
}

export default Input

export const CheckboxInput = ({ id, name, value, checked, onChange, show, logos }) => {
	return (
		<div className="flex justify-start items-center mb-2">
			<input
				className="focus:outline-none w-6 h-6 border-none"
				type={"checkbox"}
				id={id}
				name={name}
				value={value}
				checked={checked}
				onChange={onChange}
			/>
			<label className="ml-2 text-base text-white font-500" htmlFor={id}>
				{value}
			</label>

			{show &&
				logos?.length > 0 &&
				logos.map((srcs, i) => <img key={i} className="ml-2 w-10" alt={srcs} src={srcs} />)}
		</div>
	);
};
