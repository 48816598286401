import React, { useState } from "react"
import Navbar from "../Navbar/Navbar"
import Sidebar from "./Sidebar"
import Banner from "../Banner/Banner";
import { useAuth } from "../../context/AuthContext";

const Layout = ({ children }) => {

	const [showDrawer, setShowDrawer] = useState(false)

	const openSideDrawer = () => {
		setShowDrawer(true);
		document.body.style.overflow = 'hidden'
	}

	const closeSideDrawer = () => {
		setShowDrawer(false);
		document.body.style.overflow = 'unset'
	}

	const { currentUser } = useAuth()

	return (
		<>
		{currentUser.status === "Expired" && <Banner />}
		<div className="bg-white w-full flex">
			<Sidebar show={showDrawer} closeSideDrawer={closeSideDrawer} />
			<div className={"w-full xl:pl-[280px]"}>
				<Navbar openSideDrawer={openSideDrawer} />
				<div className='px-8 mt-8'>
					<div className='my-10'>
						{children}
					</div>
				</div>
			</div>
		</div>
	</>);
};

export default Layout
