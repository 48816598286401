import zIndex from "@mui/material/styles/zIndex";

export const style = {
	banner: {
    position:'sticky',
    top:'0px',
    left:'0px',
    zIndex:50,
    width:'100%',
    py: '16px',
    textAlign: 'center',
    backgroundColor: '#ff4545',
    color: '#fcfcfc',
  },
  link:{
        padding: '5px',
        textDecoration: 'none'
  }
};