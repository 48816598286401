import { ACTIONS } from "../actions/Jobber"

export const JOBBER_INITIAL_STATE = {
  message: {
    text: '', 
    success: false, 
    show: false
  },
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  businessHome: '',
  website: ''
}

export const reducer = (state, action) => {
  switch(action.type) {

    case ACTIONS.INPUT_HANDLER: 
      return {
        ...state,
        [action.payload.key]: action.payload.value
      }
    case ACTIONS.CLEAR_FIELDS:
      return JOBBER_INITIAL_STATE
    default:
      return state
  }
}
