import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createJobber, verifyManualRegisteration } from '../services/Jobber'
import { CircularProgress } from '@mui/material'

const JobberAuth = () => {

  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const authorizationCode = query.get('code')
  const clientSecret = query.get('state')
  const [loading, setLoading] = useState(false)

  useEffect(() => {

    let isSubscribed = true
    
    const postJobber = async () => {

      try {
        setLoading(true)
        if (authorizationCode && clientSecret) {
          const res = await verifyManualRegisteration(authorizationCode, clientSecret)
          if (isSubscribed) {
            toast.success(res.data.message)
            setTimeout(() => window.location.replace('https://www.snappicfix.com/getting-started'), 6000)
          }
        } else {
          const res = await createJobber(authorizationCode)
          if (isSubscribed) {
            toast.success(res.data.message)
            setTimeout(() => window.location.replace('https://www.snappicfix.com/getting-started?utm_src=jobber_connect'), 6000)
          }
        }

      } catch (error) {
        if (error.response) {
          console.log(error.response.data)
          toast.error(error.response.data.message || error.response.data)
        } else if (error.request) {
          console.log(error.message)
        } else {
          console.log(error)
        }

      } finally {
        setLoading(false)
      }
    }

    postJobber()

    return () => {
      isSubscribed = false
    }
    
  }, [authorizationCode, clientSecret])

  return (
    <section className='min-h-screen flex items-center justify-center'>
      {loading ? <CircularProgress size={60} sx={{ color: 'blue' }} /> : null}
    </section>
  )

}

export default JobberAuth
