import React, { useState, useEffect, useCallback } from "react";
import { TableSpinner } from "../../components/UI/Spinner";
import DropDown from "../../components/UI/showDropDown";
import DateSelector from "../../components/Date";
import { DIRECTION, LOGS_ICON, tableRowValues } from "../../utils/Consts";
import SectionHeading from "../../components/UI/SectionHeading.jsx";
import NoTableData from "../../components/UI/NoTableData.jsx";
import dayjs from "dayjs";
import { getLogs } from "../../api/index.js";
import { filterCallLogs } from "../../helpers/applicationHelper.js";

const NumberLogs = ({ clientId, number, children }) => {
	const [loading, setLoading] = useState(false);
	const [rows, setRows] = useState("All");
	const [selectedRow, setSelectedRow] = useState("10");
	const [dateValue, setDateValue] = useState({ startDate: "", endDate: "" });
	const [datePicker, setDatePicker] = useState(false);
	const [isDropDownOpen, setIsDropDownOpen] = useState(false);
	const [logs, setLogs] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const res = await getLogs(clientId);
			const data = filterCallLogs(number, res.data.data);
			setRows(data.length);
			setSelectedRow("All");
			setLogs(data);
		} finally {
			setLoading(false);
		}
	}, [clientId, number]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const setTableRows = (row) => {
		if (row === "All") {
			setRows(logs.length);
			setSelectedRow("All");
		} else {
			setSelectedRow(row);
			setRows(row);
		}
	};

	const datehandler = (ranges) => {
		setDateValue({
			...dateValue,
			startDate: ranges.selection.startDate,
			endDate: ranges.selection.endDate,
		});
		setDatePicker(false);
	};

	const cancelDateRange = () => {
		setDateValue({ ...dateValue, startDate: "", endDate: "" });
		setDatePicker(false);
	};

	const filterLogs = (val) => {
		if (dateValue.startDate === "" && dateValue.endDate === "") {
			return val;
		}

		if (dateValue.startDate !== "" && dateValue.endDate !== "") {
			return (
				val.dateCreated >= dayjs(dateValue.startDate).format("YYYY-MM-DD") &&
				val.dateCreated <= dayjs(dateValue.endDate).format("YYYY-MM-DD")
			);
		}

		return (
			val.dateCreated >= dayjs(dateValue.startDate).format("YYYY-MM-DD") &&
			val.dateCreated <= dayjs(dateValue.endDate).format("YYYY-MM-DD")
		);
	};

	return (
		<section className="bg-white overflow-x-auto slider py-6 min-h-[580px]">
			<SectionHeading heading="Activity" />
			{children}
			<div className="mt-4 flex flex-col md:flex-row justify-end items-start md:items-center">
				<div className="flex mt-4 md:mt-0">
					<DateSelector
						onClick={() => setDatePicker(!datePicker)}
						startDate={dateValue.startDate}
						endDate={dateValue.endDate}
						showDate={datePicker}
						onChange={datehandler}
						cancelDateRange={cancelDateRange}
					/>

					<DropDown
						show={isDropDownOpen}
						onClick={(value) => setTableRows(value)}
						displayCurrentValue={selectedRow}
						openDropDown={() => setIsDropDownOpen(!isDropDownOpen)}
						data={tableRowValues}
					/>
				</div>
			</div>

			<table className="w-full table-fixed overflow-x-auto overflow-hidden slider mt-9">
				<thead>
					<tr className="border-b-2 border-gray-400 text-sm leading-normal">
						<th className="table-heading w-48">Message</th>
						<th className="table-heading w-16">Date</th>
						<th className="table-heading w-16">Status</th>
					</tr>
				</thead>
				<tbody className="text-gray-600 text-sm">
					{loading ? (
						<TableSpinner tds={3} />
					) : logs.length === 0 ? (
						<NoTableData />
					) : (
						logs
							.filter(filterLogs)
							.slice(0, rows)
							.map((data, index) => (
								<tr key={index} className="border-b text-left border-gray-200">
									<td className="p-3">{DIRECTION[data.type] && data.message}</td>
									<td className="p-3 whitespace-nowrap">
										{dayjs(data.dateCreated).format("YYYY-MM-DD hh:mm:ss A")}
									</td>
									<td className="p-3">
										<span className="mr-3">{data.status}</span> {data.type && LOGS_ICON[data.type]}
									</td>
								</tr>
							))
					)}
				</tbody>
			</table>
		</section>
	);
};

export default NumberLogs;
