import { Box } from "@mui/material";
import React from "react";

const BaseBox = ({ children, component, ...props }) => {
	return (
		<Box component={component} {...props}>
			{children}
		</Box>
	);
};

export default BaseBox;
