// Returning mobile number in this format (123) 456 789
const formatMobileNumber = (number) => {
  
  if (!number) return ''
  const phoneNumber = number.replace(/[^\d]/g, '')
  if (phoneNumber.length < 4) return phoneNumber
  if (phoneNumber.length < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 10)}`
}

// Return area code
const validateAreaCode = (code) => {
  if (!code) return ''

  const areaCode = code.replace(/[^\d]/g, '')
  return areaCode.slice(0, 3)
}

export {
  formatMobileNumber,
  validateAreaCode
}