import React from 'react'
import { CircularProgress } from '@mui/material'
import { COLOR } from '../../utils/colors'

const AuthButton = ({ loading, text }) => {
  return (
    <button
      type='submit'
      className='w-full mb-5 h-12 border-2 border-primary-color rounded-2xl text-primary-color font-500 text-lg flex items-center justify-center'
    >
      { loading ?  <CircularProgress size={25} sx={{ color: COLOR.primaryGreen }} /> : text }
    </button>
  )
}

export default AuthButton
