import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import backgroundImage from '../assets/images/authImage.png'
import Input from "../components/UI/Input";
import { setDisplayName, setphotoURL, signInUser } from '../services/Auth/Auth'
import { toast } from "react-toastify";
import { EmailIcon, PasswordIcon } from '../utils/SvgIcons'
import Cookies from 'js-cookie'
import { useAuth } from '../context/AuthContext'
import AuthButton from "../components/Button/AuthButton";
import Camera from "../assets/images/logo192.png";


const initailState = {
	email: '',
	password: ''
}

const Login = () => {

	const [user, setUser] = useState(initailState);
	const [loading, setLoading] = useState(false);
	const { setCurrentUser, currentUser } = useAuth()

	const history = useHistory();
	const { state } = useLocation()

	if (currentUser !== null) {
		let { from } = state || { from: { pathname: "/dashboard" }};
		return <Redirect to={from || '/dashboard'} />
  }

	const inputChangeHandler = (e) => {
		const { name, value } = e.target;
		setUser({ ...user, [name]: value });
	};

	const Submit = async (e) => {
		e.preventDefault();

		if (user.email && user.password) {
			try {
				setLoading(true)
				const res = await signInUser(user.email, user.password)
				if (!res.user.displayName) {
					await setDisplayName(res.user.uid)
				}
				
				if (!res.user.photoURL) {
					await setphotoURL(res.user.displayName)
				}
				
				const { accessToken, email, uid, phoneNumber, photoURL, displayName } = res.user
				setCurrentUser({ email, uid, phoneNumber, photoURL, displayName })
				Cookies.set('access_token', accessToken, { expires: 1, secure: true, sameSite: 'strict' })
				history.replace(state?.from || '/dashboard')
				setUser(initailState)
			} catch (err) {
				console.log(err)
				toast.error('Email or password is incorrect')
			} finally {
				setLoading(false)
			}
		}
	};

	return (
		<div className="bg-main bg-center bg-cover bg-no-repeat relative" 
		style={{ backgroundImage: `url(${backgroundImage})` }}
		>
			{/* Image above the login text */}

			<div className="w-[280px] sm:w-443 mx-auto h-screen">
				<div className="h-full py-20 md:py-20 lg:py-0 flex items-center justify-center flex-col">
				<img src={Camera} alt="SnapPicFix Login" />

					<form className="w-full text-center" onSubmit={Submit}>

						<Input
							type="email"
							name="email"
							value={user.email}
							placeholder="Email"
							onChange={inputChangeHandler}
							icon={<EmailIcon />}
							displayIcon={true}
						/>
						
						<Input
							type="password"
							name="password"
							value={user.password}
							placeholder="Password"
							onChange={inputChangeHandler}
							icon={<PasswordIcon />}
							displayIcon={true}
						/>
						
						<Link
							to="/reset-password"
							className="text-right block hover:underline mb-5 text-white italic cursor-pointer font-400 -mt-5"
						>
							Forgot password
						</Link>

						<AuthButton text={'Login'} loading={loading} />

{/* Commenting out the registration link */}
{/* 

						<p className='text-center italic font-400'>
							Don't have an account?&nbsp;&nbsp;
							<Link to="/register" className="hover:underline mb-5 text-white">
								Register
							</Link>
						</p>

						*/}

					</form>
				</div>
			</div>
		</div>
	);
};

export default Login;
