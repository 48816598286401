import React from "react";
import QrCode from "../../components/QrCode/QrCode";
import BaseTypography from "../../libs/BaseTypography/BaseTypography";
import BaseBox from "../../libs/BaseBox/BaseBox";
import BaseIconButton from "../../libs/BaseIconButton/BaseIconButton";
import { PhoneFilledIcon } from "../../assets";
import { style } from "./index.style";

const ConnectContainer = ({ data }) => {
	return (
		<BaseBox sx={style.container}>
			<BaseTypography
				variant="h5"
				component="h5"
				textAlign="center"
				className="font-800"
				text="🎉 Your account is ready and customized! 🎉 The next step is to forward your unanswered calls into the system. Please scan this QR code from your business phone and hit dial to complete the process"
				sx={style.text}
			/>

			<QrCode qrCode={data.qrCode} />

			<BaseBox sx={style.phoneWrapper}>
				<BaseTypography
					variant="p"
					component="p"
					textAlign="center"
					className="font-600"
					text="Scan the QR code or Press the button to call us"
					sx={style.text}
				/>
				<BaseBox display="flex" justifyContent="center">
					<BaseIconButton
						href={data.telNumber}
						sx={style.callButton}
						disableFocusRipple={true}
						disableRipple={true}
					>
						<PhoneFilledIcon sx={style.callIcon} />
					</BaseIconButton>
				</BaseBox>
			</BaseBox>
		</BaseBox>
	);
};

export default ConnectContainer;
