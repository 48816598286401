import { Typography } from "@mui/material";
import React from "react";

const BaseTypography = ({ variant, component, text, sx, ...otherProps }) => {
	return (
		<Typography variant={variant} component={component} sx={sx} {...otherProps}>
			{text}
		</Typography>
	);
};

export default BaseTypography;
