import React from "react";
import downIcon from "../assets/svgs/downIcon.svg";
import { DateRange } from "react-date-range";
import { selectionRange } from "../utils/Consts";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import dayjs from "dayjs";

const Date = ({ startDate, endDate, onClick, showDate, onChange, cancelDateRange }) => {
	return (
		<div className="relative ml-4">
			<div
				className="flex items-center justify-between cursor-pointer h-8 border border-gray-300 rounded-md px-3"
				onClick={onClick}
			>
				<p className="mr-2 font-400">
					{!startDate || !endDate ? (
						"Select Date"
					) : (
						<span>
							{dayjs(startDate).format("MM/DD/YYYY")}&nbsp;-&nbsp;
							{dayjs(endDate).format("MM/DD/YYYY")}
						</span>
					)}
				</p>
				<img src={downIcon} className={"h-full w-[16px] transform " +(showDate ? "rotate-180" : "rotate-0")} alt="down icon" />
			</div>

			{showDate && (
				<div className="bg-white z-30 absolute top-9 right-0 cursor-pointer shadow border rounded-md p-3">
					<DateRange
						editableDateInputs={true}
						ranges={[selectionRange]}
						onChange={onChange}
						startDatePlaceholder="Start date"
						endDatePlaceholder="End date"
					/>
					<div className="flex justify-between">
						<button
							className="border-none focus:outline-none text-base text-black mt-4 text-center w-full"
							onClick={cancelDateRange}
						>
							Cancel
						</button>
					</div>
				</div>
			)}
		</div>
		
	);
};

export default Date;
