import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import AuthProvider from './context/AuthContext'
import { BrowserRouter } from "react-router-dom";
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, CssBaseline, createTheme } from '@mui/material'

const theme = createTheme()

ReactDOM.render(
	<StrictMode>
		<AuthProvider>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<App />
					<ToastContainer 
						autoClose={8000}
						pauseOnFocusLoss={false}
						limit={3}
						transition={Slide}
						pauseOnHover={true}
						newestOnTop={true}
						position={'top-right'}
					/>
				</ThemeProvider>
			</BrowserRouter>
		</AuthProvider>
	</StrictMode>,

	document.getElementById("root")
);
