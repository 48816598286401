import dispatch from "../assets/images/dispatch.png";
import houseCall from "../assets/images/housecallPro.png";
import serviceTitan from "../assets/images/serviceTitan.png";
import jobber from "../assets/images/jobber.png";
import pipeDrive from "../assets/svgs/pipedrive.svg";
import constantContact from "../assets/images/constantContact.png";

export const ManagementIntegration = [
	{
		name: "dispatch",
		value: "Dispatch",
		checked: false,
		logo: [dispatch],
		type: "checkbox",
		id: "m1",
	},
	{
		name: "housecall",
		value: "Housecall Pro",
		checked: false,
		logo: [houseCall],
		type: "checkbox",
		id: "m2",
	},
	{
		name: "serviceTitan",
		value: "Service Titan (Client ID, Client Secret, Tenant ID",
		checked: false,
		logo: [serviceTitan],
		type: "checkbox",
		id: "m3",
	},
	{
		name: "jobber",
		value: "Jobber -> Registration Link",
		checked: false,
		logo: [jobber],
		type: "checkbox",
		id: "m4",
	},
	{
		name: "clientTether",
		value: "Client Tether (API KEY + Token)",
		checked: false,
		logo: [jobber],
		type: "checkbox",
		id: "m6",
	},
	{
		name: "pipeDrive",
		value: "Pipedrive",
		checked: false,
		logo: [pipeDrive],
		type: "checkbox",
		id: "m7",
	},
	{
		name: "serviceMinder",
		value: "Service Minder",
		checked: false,
		logo: [],
		type: "checkbox",
		id: "m8",
	},
	{
		name: "other",
		value: "Other/ Webhook (Name, Customer Webhook URL, Job Webhook URL)",
		checked: false,
		logo: [],
		type: "checkbox",
		id: "s9",
	},

];

export const formatManagementIntegrationArr = (arr) => {
	// let obj = ManagementIntegration;
	// arr.map((val, i) => (obj[i].checked = val));
	// Object.keys(obj).forEach((i) => {
	// 	delete obj[i].value;
	// 	delete obj[i].type;
	// 	delete obj[i].id;
	// 	delete obj[i].logo;
	// });
	// return obj;

	const formattedArr = [];
	arr.forEach((data) => formattedArr.push(
		{
			name: data.name, 
			selected: data.checked
		}
	))

	return formattedArr;
};
