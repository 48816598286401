import React from 'react'

const NoTableData = () => {
  return (
    <tr className='relative'>
      <td className='absolute left-1/2 transform -translate-x-1/2 font-400 text-black'>No data</td>
    </tr>
  )
}

export default NoTableData
