import axios from "axios"
import { API_URL } from "../utils/url-constants"

const setupTwilio = async (data, audio) => {

  try {
    const res = await axios.post(API_URL.TWILIO_SETUP, 
      JSON.stringify({
        clientId: data.username,
        areaCode: data.areaCode,
        answeringNumber: data.answeringNumber,
        forwardNumber:  data.forwardNumber,
        voicemailEmail:  data.toEmail,
        greetingMsg1: audio.greeting_audio_url,
        greetingMsg2: audio.thankyou_audio_url,
        zapier_webhook: data.jobUrl,
        client_brand: data.brand,
        flowtype: data.flowtype
      }),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  
    if (res.status === 200) {
      const { subaccountSid, authToken, phoneNumber } = res.data
      return twilioResponse(subaccountSid, authToken, phoneNumber)
    } else {
      return twilioResponse('', '', '')
    }

  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data)
    } else if (error.request) {
      throw new Error(error.message)
    } else {
      throw new Error(error.message || error)
    }
  }
}

const twilioResponse = (accountSid, authToken, twilioNumber) => {
  return {
    accountSid: accountSid,
    authToken: authToken,
    twilioNumber: twilioNumber
  }
}

export {
  setupTwilio
}
