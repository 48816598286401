import React from "react";
import { useAuth } from "../context/AuthContext"
import Layout from "../components/UI/Layout"
import DashboardCard from '../components/Dashboard/DashboardCard'
import PageTitle from "../components/UI/PageTitle";
import { MediumSpinner } from "../components/UI/Spinner";

const Dashboard = () => {
	
	const { currentUser, loading } = useAuth()
	const pageHeading = !currentUser.brandName ? 'Welcome' : `Welcome, ${currentUser.brandName}`

	return (
		<Layout>
			{
				loading ? <div className='grid place-items-center'><MediumSpinner /></div> : 
				<>
					<PageTitle title={pageHeading} />
					<DashboardCard />
				</>
			}
		</Layout>
	);
};

export default Dashboard;
