import React, { useState } from 'react'
import backgroundImage from '../assets/images/authImage.png'
import { toast } from 'react-toastify'
import { passwordResetEmail } from '../services/Auth/Auth'
import Input from '../components/UI/Input'
import { EmailIcon } from '../utils/SvgIcons'
import AuthButton from '../components/Button/AuthButton'
import firebaseAuthError from '../errors/FirebaseAuthError'

const ForgotPassword = () => {

	const [email, setUserEmail] = useState('')
	const [loading, setLoading] = useState(false)

	const inputChangeHandler = (e) => {
		setUserEmail(e.target.value)
	}

	const Submit = async (e) => {
		e.preventDefault()

		if (email) {
			try {
				setLoading(true)
				await passwordResetEmail(email)
				setUserEmail('')
				toast.success('Password reset link send to your email')
			} catch (err) {
				toast.error(firebaseAuthError(err.code).message)
			} finally {
				setLoading(false)
			}
		}
	}

	return (
		<div className='bg-main bg-center bg-cover bg-no-repeat' style={{ backgroundImage: `url(${backgroundImage})` }}>
			<div className='w-[280px] sm:w-443 mx-auto h-screen'>
				<div className='h-full py-20 md:py-20 lg:py-0 flex items-center justify-center flex-col'>
					<form className='w-full text-center' onSubmit={Submit}>
						<h1 className='text-white mb-10 text-5xl font-600'>Enter email</h1>
						<Input
							type='email'
							name='email'
							value={email}
							placeholder='Email'
							onChange={inputChangeHandler}
							icon={<EmailIcon />}
							displayIcon={true}
						/>

						<AuthButton text={'Submit'} loading={loading} />
					</form>
				</div>
			</div>
		</div>
	)
}

export default ForgotPassword
