import React, { useCallback, useEffect, useState } from "react";
import IntegrationCard from "../Card/IntegrationCard";
import { disconnectJobberIntegration, jobberUserStatus } from "../../services/Jobber";
import { API_URL } from "../../utils/url-constants";
import ConfirmModal from "../Modals/ConfirmModal";
import JobberIcon from "../../assets/images/jobber.png";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";
import { JobberIconOutline } from "../../assets";

const JobberCard = () => {
	const [status, setStatus] = useState(true);
	const [modal, setModal] = useState(false);
	const [spinner, setSpinner] = useState({ fetchStatus: false, disconnectJobber: false });

	const { currentUser } = useAuth();

	const jobberStatus = useCallback(async () => {
		try {
			setSpinner((prevStat) => ({ ...prevStat, fetchStatus: true }));
			const status = await jobberUserStatus(currentUser.displayName);
			setStatus(status);
		} catch (err) {
			console.error(err);
		} finally {
			setSpinner((prevStat) => ({ ...prevStat, fetchStatus: false }));
		}
	}, [currentUser.displayName]);

	useEffect(() => {
		jobberStatus();
	}, [jobberStatus]);

	const disconnectJobber = async () => {
		setSpinner((prevStat) => ({ ...prevStat, disconnectJobber: true }));
		const res = await disconnectJobberIntegration(currentUser.displayName);
		setSpinner((prevStat) => ({ ...prevStat, disconnectJobber: false }));
		if (res.status) {
			toast.success(res.message);
		} else {
			toast.error(res.message);
		}
	};

	const connectJobber = () => {
		window.open(`${API_URL.SPF_DASH}/register_jobber`);
	};

	const requestJobber = () => {
		if (status) {
			setModal(true);
		} else {
			connectJobber();
		}
	};

	return (
		<>
			{modal && (
				<ConfirmModal
					setOpenModal={() => setModal(false)}
					heading="Disconnect jobber account ?"
					message="Are you sure you want to disconnect from jobber"
					buttonName={"Disconnect"}
					onClick={disconnectJobber}
					loading={spinner.disconnectJobber}
				/>
			)}
			<IntegrationCard
				icon={<JobberIconOutline />}
				title={"Jobber"}
				description={
					"SnapPicFix helps you engage with your customers within seconds. Synced to jobber so you don't miss anything"
				}
				isActive={status}
				onClick={requestJobber}
				loading={spinner.fetchStatus}
			/>
		</>
	);
};

export default JobberCard;
