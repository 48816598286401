import {
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  deleteUser
} from 'firebase/auth'
import { setDoc, doc, getDoc, deleteDoc } from "firebase/firestore"
import { auth, db } from '../../firebase'
import Cookies from 'js-cookie'
import { getDate } from '../../utils/helpers'
import firebaseAuthError from '../../errors/FirebaseAuthError'
import { insertAudio, insertClientInfo, insertLogo } from '../FirebaseFirestore'
import { setupTwilio } from '../Twilio'

/**
 * Signing user
 * @param {String} email
 * @param {String} password
 */
export const signInUser = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password)
}

/**
 * Signing out user
 */
export const logoutUser = async () => {

  try {
    await signOut(auth)
    Cookies.remove("access_token")
  } catch (error) {
    console.error(error)
    throw(firebaseAuthError(error.code))
  }
}

/**
 * @desc Registering new user
 * @param {*} data
 * @param {*} sourceRes
 * @param {*} manageRes
 * @param {*} kpiRes
 * @returns response
 */
export const registerUser = async (user, sourceRes, manageRes, kpiRes, logoRef, greetingRef) => {

  const clientId = user.username.toLowerCase()
  try {
    const res = await createUserWithEmailAndPassword(auth, user.email, user.password)
    const uid = res.user.uid
    setDoc(doc(db, "users", uid), {
      uid: uid,
      username: clientId,
      email: user.email.toLowerCase(),
      replyEmail: user.replyEmail.toLowerCase(),
      replyPhone: user.officeNumber,
      location: user.location,
      source: sourceRes,
      management: manageRes,
      managementOther: user.managementOther,
      kpi: kpiRes,
      creation: getDate(),
    });

    const brandLogo = await insertLogo(clientId, logoRef.current.files[0], uid)
    await updateUserProfile (clientId, brandLogo)
    const audioRes = await insertAudio(clientId, { greeting: greetingRef.current.files[0] }, uid)
    const twilioResult = await setupTwilio(user, audioRes)
    await insertClientInfo(user, twilioResult, uid)
    return res
  } catch (error) {
    await rollbackUserRegisteration(clientId)
    throw new Error('Failed to create account Please try again or contact us')
  }
}

/**
 * @param {String} email 
 * @returns promise
 */
export const passwordResetEmail = async (email) => {
	return await sendPasswordResetEmail(auth, email)
};


/**
 * @description Update displayName of user if user dont have already
 * @param {String} uid
 */
export const setDisplayName = async (uid) => {

  try {
    const docRef = doc(db, 'users', uid)
    const docSnap = await getDoc(docRef)
    
    if (docSnap.exists()) {
      await updateProfile(auth.currentUser, { displayName: docSnap.data().username })
    }
  } catch (error) {
    console.log(error)
  }
}

/**
 * @description Update photoUrl of user if user dont have already
 * @param {String} clientId
 */
export const setphotoURL = async (clientId) => {

  try {
    const docRef = doc(db, 'logos', clientId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      await updateProfile(auth.currentUser, { photoURL: docSnap.data().logoUrl })
    } else {
      await updateProfile(auth, { photoURL: 'https://firebasestorage.googleapis.com/v0/b/spf-dashboard-652a2.appspot.com/o/logos%2Fno_logo.png?alt=media&token=d1de772c-e55f-4a17-b460-0a9ca225a7ac' })
    }
  } catch (error) {
    console.log(error)
  }
}



/**
 * @description update user brand logo and clientId
 * @param {Object} currentUser 
 * @param {String} displayName 
 * @param {String} photoURL 
 */
export const updateUserProfile = async (displayName, photoURL) => {
  try {
    await updateProfile(auth.currentUser, { displayName: displayName, photoURL: photoURL })
  } catch (error) {
    console.log(error)
  }
}

export const rollbackUserRegisteration = async (clientId) => {
  await deleteUser(auth.currentUser)
  await deleteDoc(doc(db, 'users',  auth.currentUser.uid))
  await deleteDoc(doc(db, 'client_info', clientId))
  await deleteDoc(doc(db, 'logos', clientId))
  await deleteDoc(doc(db, 'audios', clientId))
}