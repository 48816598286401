import React from 'react'
import { useAuth } from '../../context/AuthContext'

const MenuCard = ({ onLogout }) => {

  const { currentUser, clientId } = useAuth()

  return (
    <div className='absolute top-16 right-10 bg-white rounded-lg w-auto p-4 shadow-[0px_0px_20px_3px_rgba(0,0,0,0.1)] hidden xl:block'>
      <div className='mb-3'>
        <p className='font-500 text-sm'>{currentUser.email}</p>
        <p className='font-400 text-sm mt-1 text-gray-600'>{clientId}</p>
      </div>
      <div className='border-t pt-3'>
        <button onClick={onLogout} className='font-400 text-sm text-red-600'>
          Logout
        </button>
      </div>
    </div>
  )
}

export default MenuCard