export const style = {
	modalButtons: {
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-end",
		columnGap: "20px",
	},
	button: {
		width: 200,
		height: 48,
		fontWeight: 600,
		borderRadius: 3,
	},
};
