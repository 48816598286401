import angi from "../assets/images/angi.png";
import thumbTack from "../assets/images/thumbtack.png";
// import enspire from "../assets/images/";
import localAct from "../assets/images/localAct.png";
import googleMyBusiness from "../assets/images/google-my-business.svg";
import craftJack from "../assets/images/craftjack.png";
import phoneLogo from "../assets/svgs/phone.svg";

export const Sources = [
	{
		name: "phone",
		value: "Phone",
		type: "checkbox",
		checked: false,
		id: "s1",
		logo: [phoneLogo],
	},
	{
		name: "angi",
		value: "Angi",
		checked: false,
		logo: [angi],
		type: "checkbox",
		id: "s2",
	},
	{
		name: "thumbTack",
		value: "ThumbTack",
		checked: false,
		logo: [thumbTack],
		type: "checkbox",
		id: "s3",
	},
	{
		name: "enspire",
		value: "Enspire",
		checked: false,
		logo: [],
		type: "checkbox",
		id: "s4",
	},
	{
		name: "localAct",
		value: "Local ACT",
		checked: false,
		logo: [localAct],
		type: "checkbox",
		id: "s5",
	},
	{
		name: "googleMyBusiness",
		value: "Google My Business",
		checked: false,
		logo: [googleMyBusiness],
		type: "checkbox",
		id: "s6",
	},
	{
		name: "craftJack",
		value: "Craft Jack",
		checked: false,
		logo: [craftJack],
		type: "checkbox",
		id: "s7",
	},
	{
		name: "other",
		value: "Other",
		checked: false,
		logo: [phoneLogo, angi, thumbTack],
		type: "checkbox",
		id: "s8",
	},
];

export const formatSourceArr = (arr) => {
	
	const formattedArr = [];
	arr.forEach((data) => formattedArr.push(
		{
			name: data.name, 
			selected: data.checked
		}
	))

	return formattedArr;
};
