import React from 'react'

const TableHead = ({ headings }) => {
  return (
    <thead>
      <tr className="border-b-2 border-gray-400 font-500 text-sm leading-normal">
        {headings.map((data) => <th key={data.heading} className={data.class}>{data.heading}</th>)}
      </tr>
  </thead>
  )
}

export default TableHead
