const firebaseAuthError = (code) => {
  let message
  switch (code) {
    case 'auth/email-already-in-use':
      message = 'Email not exist'
      break
    case 'auth/email-already-exists':
      message = 'Email not exist'
      break
    case 'auth/user-not-found':
      message = 'Email not found'
      break
    default:
      message = 'Please try again later or contact us'
      break
  }

  return message
}

export default firebaseAuthError